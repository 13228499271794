import { render, staticRenderFns } from "./Daxingchangsuo.vue?vue&type=template&id=54ee0c4b&scoped=true"
import script from "./Daxingchangsuo.vue?vue&type=script&lang=js"
export * from "./Daxingchangsuo.vue?vue&type=script&lang=js"
import style0 from "./Daxingchangsuo.vue?vue&type=style&index=0&id=54ee0c4b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54ee0c4b",
  null
  
)

export default component.exports